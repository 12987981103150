import { useEffect, useState } from "react";

import { useRouter } from "next/router";

import DayJsUtils from "@date-io/dayjs";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/next"
import ptBR from "dayjs/locale/pt-br";
import { Metadata } from "next";

import ErrorBoundary from "@/components/error-boundary";

import { Constants } from "../components/molecules/advanced-settings-form";
import { loadIntercomScript } from "../components/organisms/players-list";
import { ToastProvider } from "../context/useToast";
import { UserAplicationProvider } from "../context/useUser";
import AuthService from "../services/auth";

import "../styles/globals.css";

import useLocalStorage from "../internal-libs/uselocalstorage";
import { TranslateAccessLevel } from "../context/onlyUserType";

import "./react-datepicker.css";
import Head from "next/head";

import { GlobalProvider } from "../context/useGlobal";

const publicRoutes = ["/login", "/accept-invite", "/change-password"];

export const metadata: Metadata = {
  title: {
    template: "%s | Gamefik Escolas no level máximo.",
    default: "Gamefik"
  }
};

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  const [alreadyLoaded, setAlreadyLoaded] = useState(false);

  useEffect(() => {
    const handleRouteChangeComplete = (url: string) => {
      const hashIndex = url.indexOf("#");
      if (hashIndex !== -1) {
        const id = url.substring(hashIndex + 1);
        setTimeout(() => {
          if (alreadyLoaded) {
            return;
          }
          setAlreadyLoaded(true);
          const element = document.getElementById(id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }, 900);
      }
    };

    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    handleRouteChangeComplete(window.location.href);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router.events]);

  const [isAuth, setIsAuth] = useState(null);

  const checkPublicRoutes = async () => {
    for (const route of publicRoutes) {
      if (router.pathname.includes(route)) {
        return true;
      }
    }
  };

  // useEffect(() => {
  //   const adjustViewport = () => {
  //     const screenWidth = window.innerWidth;

  //     let zoomLevel = 1;

  //     if (screenWidth < 1920) {
  //       zoomLevel = Math.max(screenWidth / 1920, 0.7);
  //     }

  //     const viewport = document.querySelector('meta[name="viewport"]');
  //     if (viewport) {
  //       viewport.setAttribute(
  //         "content",
  //         `width=device-width, initial-scale=${zoomLevel}, maximum-scale=${zoomLevel}, minimum-scale=${zoomLevel}, user-scalable=no`
  //       );
  //     } else {
  //       const meta = document.createElement("meta");
  //       meta.name = "viewport";
  //       meta.content = `width=device-width, initial-scale=${zoomLevel}, maximum-scale=${zoomLevel}, minimum-scale=${zoomLevel}, user-scalable=no`;
  //       document.head.appendChild(meta);
  //     }
  //   };

  //   // if not on /purchases
  //   if (window.location.pathname !== "/purchases") {
  //     adjustViewport();
  //     window.addEventListener("resize", adjustViewport);
  //   }

  //   return () => {
  //     window.removeEventListener("resize", adjustViewport);
  //   };
  // }, []);

  const checkIsAuthenticated = async () => {
    const resAuth = await AuthService.isAuthenticated();
    if (resAuth) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }

    if (!resAuth && (await !checkPublicRoutes())) {
      router.push("/login");
    }
  };

  const [userAccessLevel] = useLocalStorage(Constants.LSK_UserAccessLevel, 0);

  useEffect(() => {
    checkIsAuthenticated();
    if (userAccessLevel) {
      if (typeof window !== "undefined") {
        if (
          window.location.pathname !== "/players" &&
          window.location.pathname !== "/details" &&
          window.location.pathname !== "/login"
        ) {
          loadIntercomScript(undefined, TranslateAccessLevel(userAccessLevel));
        }
      }
    }
  }, [userAccessLevel]);

  const queryClient = new QueryClient();

  return (
    <MuiPickersUtilsProvider locale={ptBR} utils={DayJsUtils}>
      <Head>
        <title>Gamefik Escolas no level máximo.</title>
        <link rel="manifest" href="/manifest.json" />

      </Head>
      <ErrorBoundary>
        <SpeedInsights />
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools
            initialIsOpen={false}
            position="right"
            buttonPosition="bottom-left"
          />
          <GlobalProvider>
            <ToastProvider>
              <UserAplicationProvider isAuth={isAuth}>
                <Component {...pageProps} />
                <script
                  dangerouslySetInnerHTML={{
                    __html: `
                (function(c,l,a,r,i,t,y){
                  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                  })(window, document, "clarity", "script", "lqh11nua5t");
                  `
                  }}
                />
                <Analytics />
              </UserAplicationProvider>
            </ToastProvider>
          </GlobalProvider>
        </QueryClientProvider>
      </ErrorBoundary>

      
    </MuiPickersUtilsProvider>
  );
}

String.prototype.normalizeText = function(): string {
  return this.normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim();
};

Array.prototype.toReversed = function(): any[] {
  return [...this].reverse();
};

declare global {
  interface String {
    normalizeText(): string;
  }
}

export default MyApp;

/* eslint-disable prettier/prettier */
import { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";

import { MissionsFilter } from "@/components/organisms/missoes-filter";
import { useToast } from "@/context/useToast";
import { ACCESS_LEVELS, useUser } from "@/context/useUser";
import { useMissoes, useUsers } from "@/context/useUses";

import useLocalStorage from "../../../internal-libs/uselocalstorage";
import Client from "../../../services/client";
import Settings from "../../../services/settings";
import AttitudeList from "../../atoms/attitudes-list";
import CircularStatic from "../../atoms/circular-progress";
import SnackAlert from "../../atoms/snack-alert";
import { Constants } from "../advanced-settings-form";

const client = new Client();

function AttitudesDialog(props: {
  players: any[];
  onClose: () => void;
  onError: (arg0: string) => void;
  open: boolean;
  afterApply: (players: any[], attitudes: any[]) => void;
}) {
  const [open, setOpen] = useState(false);
  const [openAttitude, setOpenAttitude] = useState(false);
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [timerId, setTimerId] = useState(null);
  const [disable, setDisable] = useState(true);
  const [players, setPlayers] = useState([]);
  const [selectedAttitudes, setSelectedAttitudes] = useState({});
  const { data: missoes, refetch: loadMissions } = useMissoes();
  const [myClassesAsATeacherOrAdmin, setClasses] = useLocalStorage(
    "Classes",
    []
  );
  const getClassesInClient = async () => {
    const result = await client.getClassesByTeacher();
    setClasses(result);
  };

  useEffect(() => {
    getClassesInClient();
  }, []);

  const [games, setGames] = useLocalStorage(Constants.LSK_Games, []);
  const [badAttitudes, setBadAttitudes] = useLocalStorage(
    Constants.LSK_BadAttitudes,
    []
  );
  const [goodAttitudes, setGoodAttitudes] = useLocalStorage(
    Constants.LSK_GoodAttitudes,
    []
  );
  const [currentObjectives, setCurrentObjectives] = useLocalStorage(
    Constants.LSK_OldMissions,
    []
  );
  const [completeMissions, setCompleteMissions] = useLocalStorage(
    Constants.LSK_CompletedMissions,
    null
  );

  const checkAttitudeGameAtCurrentGames = (games: any[], currentGames: any) => {
    for (const game of currentGames) {
      if (games.findIndex((g: { id: any }) => g.id === game) === -1) {
        return false;
      }
    }
    return true;
  };

  const handleGames = async (currentGames: string | any[]) => {
    const response = (await client.getAttitudes()).filter(a => !a.isInactive);
    let attitudes = [];
    for (const attitude of response) {
      if (attitude.games && attitude.games.length) {
        const checkInGame = checkAttitudeGameAtCurrentGames(
          attitude.games,
          currentGames
        );
        if (checkInGame) {
          attitudes.push(attitude);
        }
      }
    }
    attitudes = attitudes.filter(a => a.recurrences[0]?.isActive !== true);
    setGoodAttitudes(attitudes.filter(at => at && at.type === "good"));
    setBadAttitudes(attitudes.filter(at => at && at.type === "bad"));

    setCurrentObjectives(attitudes.filter(at => at && at.type === "objective"));
    setCompleteMissions(null);

    if (props.players.length === 1 && currentGames.length) {
      client
        .getCompleteMissions(currentGames[0], props.players[0].id)
        .then(result => setCompleteMissions(result));
    }
  };

  const handleCheck = (data: {}, type: string) => {
    if (data && Object.keys(data).length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }

    setSelectedAttitudes({
      ...selectedAttitudes,
      [type]: data
    });
  };

  const handleCurrentGames = async (currentPlayers: any) => {
    const games = [];
    for (const player of currentPlayers) {
      const gameIndex = games.findIndex(g => g === player.games[0].id);
      if (gameIndex === -1) {
        games.push(player.games[0].id);
      }
    }
    setGames(games);
    handleGames(games);
  };

  const finish = () => {
    setSaved(false);
    setError(false);
    props.onClose();
  };

  const handleSnackBarClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
    setDisable(true);

    const id = setTimeout(() => {
      applyAttitudes();
      setOpen(false);
      setDisable(false);
    }, 300);
    setTimerId(id);
  };

  const handleUndo = () => {
    clearTimeout(timerId);
    setOpen(false);
    setDisable(false);
  };

  const applyAttitudes = async () => {
    setDisable(true);
    setOpenAttitude(true);
    const { afterApply } = props;
    const attitudes = [];
    Object.keys(selectedAttitudes).map(key => {
      if (selectedAttitudes[key]) {
        Object.keys(selectedAttitudes[key]).map(i =>
          attitudes.push(selectedAttitudes[key][i])
        );
      }
    });

    await applyAttitudesOnPlayersByGames(
      players,
      attitudes,
      games,
      setError,
      setSaved,
      afterApply
    );

    props.onClose();
    setDisable(false);
    setOpenAttitude(false);
  };

  useEffect(() => {
    if (props.players) {
      props.players.map(
        (player: {
          games: string | any[];
          firstName: string;
          surname: string;
        }) => {
          if (!player.games || player.games.length === 0) {
            props.onError(
              "Um ou mais jogadores selecionados não estão vinculados há um jogo (" +
                player.firstName +
                " " +
                player.surname +
                ")."
            );
            setGames([]);
            return;
          }
        }
      );
      setPlayers(props.players);
      handleCurrentGames(props.players);
    }
  }, []);
  const { data: users } = useUsers();
  const { userAccessLevel } = useUser();

  const filteredMissions = missoes?.filter(mission => {
    // !
    if (!myClassesAsATeacherOrAdmin) return false;
    const whoCreated = users.find(u => u._id === mission.createdBy);
    if (!whoCreated) return false;

    // * diretor e secretário pode ver tudo
    if (userAccessLevel > ACCESS_LEVELS.TEACHER) return true;

    // ! se os players selecionados não são do game da missão, não pode ver
    if (
      !players.some(p =>
        p.games.some(g =>
          Object.entries(mission.rewards).some(([key, value]) => key === g.id)
        )
      )
    ) {
      return false;
    }

    // * se a missão foi criada pelo próprio usuário, pode ver
    if (mission.createdBy === Settings?.getSettings()?.userData._id) {
      return true;
    }

    // ! se a missão foi criada por um diretor ou secretário e eu sou um professor.
    if (
      userAccessLevel === ACCESS_LEVELS.TEACHER &&
      (whoCreated.groups.includes("admin") ||
        whoCreated.groups.includes("secretary"))
    ) {
      return (
        mission.teacherCanValidate &&
        (mission.filterTurmasId.length === 0 ||
          mission.filterTurmasId.some(c =>
            myClassesAsATeacherOrAdmin.find(cl => cl.id === c)
          ))
      );
    }

    // // ! se tiver relação com as turmas da missão, pode ver
    // for (const cl of myClassesAsATeacherOrAdmin) {
    //   if (mission.filterTurmasId?.find(c => c === cl.id)) {
    //     return true;
    //   }
    // }

    return false;
  });

  const missoesConvertidas = filteredMissions.map(m => {
    const convertedToAttitude: IAttitude = {
      id: "@newmission" + m._id,
      attitudeId: "@newmission" + m._id,
      name: m.titulo,
      desc: m.descricao,
      worldId: m.world,
      type: "objective",
      order: 0,
      games: Object.entries(m.rewards).map(([key, value]) => {
        const asd: IGame = {
          worldId: m.world,
          gameId: key,
          name: key,
          points: value
        };
        return asd;
      }),
      createdAt: undefined,
      updatedAt: undefined
    };
    return convertedToAttitude;
  });

  const objectives = [...currentObjectives, ...missoesConvertidas];

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Aplicar atitudes</DialogTitle>

      <DialogContent
        style={{
          minHeight: "60dvh"
        }}
      >
        {players && players.length == 1 && (
          <DialogContentText>
            {players[0].firstName} {players[0].surname}
          </DialogContentText>
        )}
        {goodAttitudes.length > 0 && (
          <div>
            <strong>Atitudes positivas</strong>
            <AttitudeList
              attitudes={goodAttitudes}
              games={games}
              onSelect={(data: any) => handleCheck(data, "good")}
            />
          </div>
        )}
        {badAttitudes.length > 0 && (
          <div>
            <strong>Atitudes negativas</strong>
            <AttitudeList
              attitudes={badAttitudes}
              games={games}
              onSelect={(data: any) => handleCheck(data, "bad")}
            />
          </div>
        )}
        <div>
          <strong>Missões</strong>
          <AttitudeList
            attitudes={objectives}
            completeMissions={completeMissions}
            games={games}
            onSelect={(data: any) => handleCheck(data, "objective")}
          />
        </div>
        <Typography variant="h6">Alunos que receberão:</Typography>
        <List dense>
          {players &&
            players.map(player => (
              <ListItem key={player.id}>
                <ListItemText
                  primary={`${player.firstName} ${player.surname}`}
                />
              </ListItem>
            ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="primary">
          Cancelar
        </Button>
        <Button
          onClick={handleOpen}
          color="primary"
          variant="contained"
          disabled={disable}
          autoFocus
        >
          Aplicar
        </Button>
      </DialogActions>
      <Snackbar
        open={open}
        onClose={handleSnackBarClose}
        autoHideDuration={7000}
        disableWindowBlurListener
        message={
          <div style={{ color: "#55FCB9" }}>
            Atitude / Missão sendo aplicada...
          </div>
        }
        action={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: 150
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button size="large" color="secondary" onClick={handleUndo}>
                Desfazer
              </Button>
            </div>
            <CircularStatic />
          </div>
        }
      />
      <SnackAlert
        open={Boolean(saved)}
        onClose={finish}
        type="success"
        disableWindowBlurListener
      >
        Atitudes aplicadas com sucesso,{" "}
        <strong>
          você só poderá ver os pontos atualizados após recarregar a pagina.
        </strong>
      </SnackAlert>
      <Snackbar
        open={openAttitude}
        onClose={handleSnackBarClose}
        message={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "15px"
            }}
          >
            <span style={{ color: "#55FCB9", paddingTop: "3px" }}>
              Aplicando atitudes/missões...
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: 10
              }}
            />
            <CircularProgress
              color="inherit"
              size={24}
              style={{ color: "#55FCB9" }}
            />
          </div>
        }
      />
      <SnackAlert
        open={Boolean(error)}
        onClose={finish}
        autoHideDuration={10000}
        type="error"
        disableWindowBlurListener
      >
        <span
          dangerouslySetInnerHTML={{
            __html: `<div style="width: 280px; height: 120px; overflow-y: scroll;">${error}</div>`
          }}
        />
      </SnackAlert>
    </Dialog>
  );
}
export default AttitudesDialog;

export async function applyAttitudesOnPlayersByGames(
  selectedPlayers: any[],
  selectedAttitudesDataClasses: IAttitude[],
  allGamesIds: any[],
  setError,
  setSaved,
  afterApply: (players: any[], attitudes: any[]) => void
) {


  const getPlayersByGame = (game: string) => {
    const playersInGame = selectedPlayers.filter(pl => pl.games[0].id === game);
    return playersInGame;
  };
  const getTextError = (
    item: { error: { message: string }; attitude: any; player: any },
    game: any
  ) => {
    if (item.error) {
      let messagePrefix = "";
      if (item.error.message === "Duplicated transaction.") {
        messagePrefix = `Lançamento duplicado.`;
      }
      const message = `<strong>[${game}]</strong>  Erro ao aplicar a atitude <strong>${item.attitude}</strong> para o <strong>${item.player}</strong>.\n${messagePrefix}`.trim();
      return message;
    }
    return false;
  };
  const applyAttitudesByGame = async (
    attitudes: any[],
    game: any
  ): Promise<string[]> => {
    const playersInGame = getPlayersByGame(game);
    const errorMessages = [];


    const idsOfNewMissions = attitudes.filter(mission =>
      mission.id.includes("@newmission")
    ).map(missions => missions.id);

    try {
        for (const idSelectedAttitude of idsOfNewMissions) {
        await Client.authenticatedRequest({
          method: "POST",
          path: "missoes2/comprovantes/comprovar",
          data: {
            studentsId: playersInGame.map(p => p.id),
            missaoId: idSelectedAttitude.replace("@newmission", "")
          },
          headers: {
            "Content-Type": "application/json"
          }
        });
      }
    } catch (e) {
      // showToast("Algum erro aconteceu, contacte o suporte.", "white");
    }

    try {
      if (playersInGame.length === 0) return [];
      if (attitudes.length === 0) return [];

      const result = await client.postAttitudesOnGame(
        game,
        {
          players: playersInGame,
          attitudes: JSON.parse(JSON.stringify(attitudes))
        },
        {
          notNotify: false
        }
      );
      for (const item of result) {
        const message = getTextError(item, game);
        if (message && !errorMessages.includes(message)) {
          errorMessages.push(message);
        }
      }
    } catch (error) {
      if (error.response.message) {
        errorMessages.push(error.response.message);
      }
    }
    return errorMessages;
  };
  try {
    console.clear();
    console.log(selectedAttitudesDataClasses);
    const promises = [];
    for (const game of allGamesIds) {
      promises.push(applyAttitudesByGame(selectedAttitudesDataClasses, game));
    }
    const result = await Promise.all(promises);
    let errorMessage = [];
    for (const r of result) {
      if (r?.length > 0) {
        errorMessage = [...r, ...errorMessage];
      }
    }
    if (errorMessage.length > 0) {
      const currentError = `
        ${errorMessage.join(
          "<br><br>"
        )}<br></br><strong>Todas as outras atitudes foram lançadas corretamente.</strong><br></br>`;
      setError(currentError as any);
    } else {
      setSaved(errorMessage.length === 0);
    }
    if (errorMessage.length === 1) {
      setError(errorMessage[0]);
    }
    afterApply(selectedPlayers, selectedAttitudesDataClasses);
  } catch (error) {
    console.error(error);
  }
}

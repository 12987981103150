import React from 'react';

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { useQuery } from '@tanstack/react-query';
import { compareDesc } from 'date-fns';
import moment from "moment";

import { useGames, useMissoes } from '@/context/useUses';

import Client from "../../../services/client";
import HistoryList from "../../atoms/history-list";
import RocketLoading from "../../atoms/loading";
import TabPanel from "../../atoms/tab-panel";

const client = new Client();

const divideHistoryByTimeAgo = (data: { createdAt: Date }[]) => {
  const ret = {
    today: [],
    yesterday: [],
    thisWeek: [],
    moreTimeAgo: []
  };
  data.map(item => {
    const today = moment().endOf("day");
    const itemDate = moment(item.createdAt);
    const timeAgo = today.diff(itemDate, "days");
    if (timeAgo === 0) {
      ret.today.push(item);
    } else if (timeAgo === 1) {
      ret.yesterday.push(item);
    } else if (timeAgo < 7) {
      ret.thisWeek.push(item);
    } else {
      ret.moreTimeAgo.push(item);
    }
  });
  return ret;
};

const formatHistory = (history: any[], games: any[]) => {
  return history?.map(entry => {
    const gameName = games?.find(game => game.id === entry?.game)?.name

    return {
      id: entry?.id,
      createdAt: entry.creationTime,
      email: entry?.metadata?.email,
      points: entry?.points,
      gameName: gameName,
      title: entry?.attitude?.name,
    }
  })
}

const formatReceipts = (receipts: any[], missions: any[]) => {
  return receipts?.map(comprovante => {
    const { createdAt, _id, missaoId, rewarded } = comprovante

    const missionName = missions.find(mission => mission._id === missaoId)?.titulo

    const title = `Missao aplicada: ${missionName}`;

    return {
      id: _id,
      createdAt,
      title,
      isGame: true,
    }
  })
}

type Props = {
  open: boolean
  onClose: () => void
  playerId: string
}

const HistoryDialog = ({ onClose, open, playerId }: Props) => {
  const { data: missions } = useMissoes()
  const { data: games } = useGames()

  const { data, isLoading } = useQuery({
    queryKey: ["history-information", playerId],
    queryFn: async () => {
      const [history, receipts] = await Promise.all(
        [
          client.getHistory(playerId),
          client.getAllReceipts(),
        ]
      );

      return { history, receipts }
    }
  })

  const history = React.useMemo(() => {

    if (!data || !missions || !games) {
      return {
        today: [],
        yesterday: [],
        thisWeek: [],
        moreTimeAgo: []
      }
    }

    const { history: allHistory, receipts } = data

    const currUserReceipts = receipts.filter(e => e.playerId === playerId)
    const formatedComprovantes = formatReceipts(currUserReceipts, missions)
    const formatedResult = formatHistory(allHistory, games).filter(e => !JSON.stringify(e).includes("Otimização"))
    const attitudeHistory = divideHistoryByTimeAgo(formatedResult);
    const comprovanteHistory = divideHistoryByTimeAgo(formatedComprovantes)

    const result = {
      today: [...attitudeHistory.today, ...comprovanteHistory.today].sort((a, b) => compareDesc(a.createdAt, b.createdAt)),
      yesterday: [...attitudeHistory.yesterday, ...comprovanteHistory.yesterday].sort((a, b) => compareDesc(a.createdAt, b.createdAt)),
      thisWeek: [...attitudeHistory.thisWeek, ...comprovanteHistory.thisWeek].sort((a, b) => compareDesc(a.createdAt, b.createdAt)),
      moreTimeAgo: [...attitudeHistory.moreTimeAgo, ...comprovanteHistory.moreTimeAgo].sort((a, b) => compareDesc(a.createdAt, b.createdAt))
    }

    return result
  }, [data, missions, games])

  const printHistory = () => {
    const printContents = document.getElementById("print").innerHTML;
    document.body.innerHTML = printContents;
    document.body.style.zoom = "70%";
    window.print();
    window.location.reload();
  }

  if (isLoading) return <RocketLoading />;

  const hasAnyValue = Boolean(Object.values(history).reduce((acc, curr) => acc.concat(curr), []).length)

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 24px"
        }}
      >
        <DialogTitle id="alert-dialog-title">Histórico</DialogTitle>
        <div
          style={{
            color: "#3f51b5",
            cursor: "var(--cursor-pointer)",
            padding: "0 24px"
          }}
          onClick={printHistory}
        >
          imprimir histórico
        </div>
      </div>

      <DialogContent id="print">
        <TabPanel
          items={[
            {
              title: "Tudo",
              children: (
                <>
                  <HistoryList history={[...history.today]} title="Hoje" />
                  <HistoryList history={[...history.yesterday]} title="Ontem" />
                  <HistoryList
                    history={[...history.thisWeek]}
                    title="Esta semana"
                  />
                  <HistoryList
                    history={[...history.moreTimeAgo]}
                    title="Em um passado não tão distante"
                  />
                  {!hasAnyValue && (
                    <Typography>
                      Não existe registro de atitudes para este aluno.
                    </Typography>
                  )}
                </>
              )
            }
          ]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default HistoryDialog;
